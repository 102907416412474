import React from 'react';
import MediaQuery from 'react-responsive';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Heading from '../components/Heading';
import {
  ContentWrapper,
  StyledContent,
  StyledName,
  imgStyle,
  divStyle,
} from './AboutTemplate.style';

const AboutTemplate = ({ hashName }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "img-lawyer-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 610, maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Heading title="kim jesteśmy" hashName={hashName} />
        <ContentWrapper>
          <MediaQuery minWidth={600}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Katarzyna Rybacka - adwokat"
              title="Katarzyna Rybacka"
              imgStyle={imgStyle}
              style={divStyle}
            />
          </MediaQuery>

          <StyledContent lang="pl-PL">
            <MediaQuery minWidth={1600}>
              <StyledName>
                KANCELARIA ADWOKACKA <br /> KATARZYNA RYBACKA
              </StyledName>
            </MediaQuery>

            <p>
              Od wielu lat udzielam pomocy prawnej zarówno przedsiębiorcom jak i
              Klientom indywidualnym, reprezentuję ich w postępowaniach
              sądowych, pomagam negocjować warunki ugód oraz tworzę umowy.
              Ponadto w obszarze moich zainteresowań leży prawo ochrony
              zwierząt, współpracuję z Towarzystwem Opieki nad Zwierzętami,
              reprezentując je w procesach karnych i cywilnych.
            </p>

            <p>
              W swoim działaniu zawsze dbam o dobro klienta, zachowując z nim
              pełnię współpracy i informując o kolejnych etapach załatwianych
              spraw. Rzetelnie przedstawiam możliwe scenariusze działania oraz
              ich możliwe konsekwencje. Jako adwokat działam z pełnym
              poszanowaniem zasad etyki zawodowej, zapewniając pełną poufność na
              każdym etapie.
            </p>
            <p>
              Choć siedzibą mojej kancelarii jest Krotoszyn, działam również na
              obszarze Milicza, Ostrowa Wielkopolskiego, Pleszewa, Jarocina,
              Gostynia, Rawicza i Kalisza a także współpracuję z kancelariami
              adwokackimi i radcowskimi z Poznania.
            </p>
          </StyledContent>
        </ContentWrapper>
      </>
    )}
  />
);

AboutTemplate.propTypes = {
  hashName: PropTypes.string.isRequired,
};

export default AboutTemplate;
