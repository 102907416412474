import React from 'react';
import { FaLandmark, FaGavel, FaUsers, FaBriefcase } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Heading from '../components/Heading';
import Specialization from '../components/Specialization/Specialization';
import { SpecializationsWrapper } from './SpecializationsTemplate.style';

const SpecializationTemplate = ({ hashName, iconSize }) => {
  const currentSpec = [
    {
      id: 1,
      icon: <FaBriefcase size={iconSize} />,
      title: 'Prawo Gospodarcze',
      description:
        'Sporządzanie opinii prawnych\nPrzygotowywanie i opiniowanie umów\nNegocjowanie warunków umów i ugód\nKompleksowa obsługa przedsiębiorców\nProwadzenie postępowań gospodarczych',
    },
    {
      id: 2,
      icon: <FaGavel size={iconSize} />,
      title: 'Prawo Cywilne',
      description:
        'Sprawy o zapłatę\nRoszczenia z umów\nOchrona dóbr osobistych\nOdszkodowanie i zadośćuczynienie\nPostępowania spadkowe: stwierdzenie nabycia spadku, zachowki, działy spadku',
    },
    {
      id: 3,
      icon: <FaLandmark size={iconSize} />,
      title: 'Prawo Karne',
      description:
        'Sprawy o wykroczenia\nReprezentacja pokrzywdzonych\nObrona w postępowaniu przygotowawczym i sądowym\nPostępowanie wykonawcze: odroczenie wykonania kary, przerwa w wykonywaniu kary, przedterminowe warunkowe zwolnienie, dozór elektroniczny',
    },

    {
      id: 4,
      icon: <FaUsers size={iconSize} />,
      title: 'Prawo Rodzinne',
      description:
        'Alimenty\nRozwód i separacja\nPrzysposobienie dziecka\nRozdzielność majątkowa\nPodział majątku wspólnego małżonków\nSprawy dotyczące władzy rodzicielskiej i kontaktów z dziećmi',
    },
  ];

  return (
    <>
      <Heading title="Obszary naszego działania" hashName={hashName} />
      <SpecializationsWrapper>
        {currentSpec.map(spec => (
          <Specialization
            key={spec.id}
            icon={spec.icon}
            title={spec.title}
            description={spec.description}
          />
        ))}
      </SpecializationsWrapper>
    </>
  );
};

SpecializationTemplate.propTypes = {
  hashName: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
};

export default SpecializationTemplate;
