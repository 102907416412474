import styled from 'styled-components';

export const StyledTitle = styled.h2`
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ theme }) => theme.font.size.m};
  text-transform: uppercase;
  text-align: left;
  margin: 3.3rem 0 0.9rem 0;
  padding-bottom: 0.9rem;

  &::after {
    content: '';
    position: absolute;
    bottom: -10%;
    left: 10%;
    width: 20%;
    height: 3px;

    background-color: ${({ theme }) => theme.secondaryGreen};
    transform: translateX(-50%);
  }

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.l};
  }

  ${({ theme }) => theme.mq.desktopSmall} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.primary};
`;

export const StyledDescription = styled.article`
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
  margin-left: 2.8rem;

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.mm};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    font-size: ${({ theme }) => theme.font.size.mmm};
  }
`;

export const StyledParagraph = styled.p`
  margin: 1rem 0;
  text-align: left;
  position: relative;
  ::before {
    content: '-';
    position: absolute;

    left: -13px;
  }
`;

export const StyledGridBox = styled.div`
  width: 100%;
`;
