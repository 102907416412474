import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../components/Heading';
import MapComponent from '../components/Contact/MapComponent';
import ContactForm from '../components/Contact/ContactForm';
import { ContactWrapper, MapWrapper } from './ContactTemplate.style';

const ContactTemplate = ({ hashName }) => {
  return (
    <>
      <Heading title="Kontakt" hashName={hashName} />
      <ContactWrapper>
        <ContactForm />
        <MapWrapper>
          <MapComponent />
        </MapWrapper>
      </ContactWrapper>
    </>
  );
};

ContactTemplate.propTypes = {
  hashName: PropTypes.string.isRequired,
};

export default ContactTemplate;
