import styled from 'styled-components';

export const StyledForm = styled.form`
  display: block;
  width: 95%;
  margin: 0 auto;
  padding: 2.9rem 0;
  border: none;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.black};

  ${({ theme }) => theme.mq.desktopSmall} {
    width: 90%;
  }

  ${({ theme }) => theme.mq.desktopBig} {
    padding: 0;
  }
`;

export const GroupWrapper = styled.div``;

export const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.desktopBig} {
    flex-direction: row;
  }
`;

export const StyledLabel = styled.label`
  display: block;
  margin-left: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.4s;
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  max-height: 400px;
  resize: vertical;
  font-family: 'Montserrat', sans-serif;
  color: inherit;
  outline: none;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  padding: 2rem 2rem;
  border: none;

  ::-webkit-input-placeholder {
    color: black;
  }
`;

export const SubmitButton = styled.button`
  flex: 1;
  border: none;
  text-transform: uppercase;
  padding: 3rem;
  margin-top: 1.5rem;
  background-color: ${({ theme }) => theme.primary};
  color: #f4f4f4;
  border-radius: 3px;
  font-size: 1.8rem;

  ${({ theme }) => theme.mq.desktopBig} {
    margin-top: 0;
  }

  :hover {
    box-shadow: 0 1rem 2rem rgba(black, 0.1);
    transform: scale(1.01);
    transition: all 0.2s;
  }
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 1.4rem;
  color: inherit;
  padding: 2.1rem 2rem;
  border: none;
  border-bottom: 3px solid transparent;
  border-radius: 4px;

  ::-webkit-input-placeholder {
    color: black;
  }

  :focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(black, 0.1);
    border-bottom: 3px solid #4f4f4f;
  }

  :focus:invalid {
    border-bottom: 3px solid red;
  }
`;

export const StyledErrorSpan = styled.span`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.primaryRed};
  font-size: ${({ theme }) => theme.font.size.ms};
`;
