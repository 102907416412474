import React from 'react';
import PropTypes from 'prop-types';
import { StyledHeading } from './Heading.style';

const Heading = ({ title, hashName }) => (
  <StyledHeading id={hashName}>{title}</StyledHeading>
);

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  hashName: PropTypes.string.isRequired,
};

export default Heading;
