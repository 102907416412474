import styled from 'styled-components';

export const SpecializationsWrapper = styled.div`
  width: ${({ theme }) => theme.widthValue.xxl};
  margin: 0 auto;

  ${({ theme }) => theme.mq.desktopSmall} {
    width: ${({ theme }) => theme.widthValue.xl};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 25px;
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    width: ${({ theme }) => theme.widthValue.l};
  }

  ${({ theme }) => theme.mq.desktopBig} {
    width: ${({ theme }) => theme.widthValue.m};
    column-gap: 35px;
    row-gap: 15px;
  }

  ${({ theme }) => theme.mq.desktopHuge} {
    width: ${({ theme }) => theme.widthValue.s};
  }
`;
