import styled from 'styled-components';

export const StyledHeading = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ theme }) => theme.font.size.xxl};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.8rem;
  font-weight: 100;
  margin: 4rem auto 4rem auto;

  ${({ theme }) => theme.mq.tablet} {
    margin: 7rem auto 5rem auto;
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    font-size: ${({ theme }) => theme.font.size.xxxxl};
    margin: 7rem auto;
  }
`;
