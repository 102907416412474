import styled from 'styled-components';

export const MapWrapper = styled.div`
  height: 60vh;
  width: 100%;
  position: relative;

  ${({ theme }) => theme.mq.desktopBig} {
    height: inherit;
    margin-left: 30px;
  }
`;

export const ContactWrapper = styled.div`
  ${({ theme }) => theme.mq.desktopBig} {
    display: flex;
    width: ${({ theme }) => theme.widthValue.m};
    margin: 0 auto 50px auto;
  }

  ${({ theme }) => theme.mq.desktopHuge} {
    width: ${({ theme }) => theme.widthValue.s};
  }
`;
