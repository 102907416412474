import styled from 'styled-components';

export const StyledBox = styled.div`
  position: absolute;
  top: 45%;
  left: 35%;
  font-family: 'Montserrat', sans-serif;
  transform: translate(-50%, -50%);
  padding: 3rem 3.5rem;
  border: 2px solid ${({ theme }) => theme.primaryGrey};
`;

export const StyledMotto = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.primaryGrey};
  font-size: ${({ theme }) => theme.font.size.xxxl};
  font-weight: 100;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 100%;
  left: 71%;
  transform: translate(-50%, -50%);
  width: 27rem;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.primaryGrey};
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.primaryGreen};
  border: none;
  padding: 1.2rem 2rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;
