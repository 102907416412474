import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

export const StyledInnerWrapper = styled.div`
  position: relative;
  height: 80vh;
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  clip-path: polygon(0 0, 100% 0, 100% 70vh, 0 100%);
`;
