import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledGridBox,
  StyledTitle,
  StyledWrapper,
  StyledDescription,
  IconWrapper,
  StyledParagraph,
} from './Specialization.style';

const Specialization = ({ icon, title, description }) => {
  return (
    <StyledGridBox>
      <StyledTitle>{title}</StyledTitle>
      <StyledWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <StyledDescription>
          {description.split('\n').map(item => (
            <StyledParagraph key={item}>{item}</StyledParagraph>
          ))}
        </StyledDescription>
      </StyledWrapper>
    </StyledGridBox>
  );
};

Specialization.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default Specialization;
