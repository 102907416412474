import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

class MapComponent extends Component {
  static defaultProps = {
    center: {
      lat: 51.6954181,
      lng: 17.4327915,
    },
    zoom: 16,
  };

  renderMarkers(map, maps) {
    new maps.Marker({
      position: {
        lat: 51.6954181,
        lng: 17.4327915,
      },
      map,
      title: 'Kancelaria - Katarzyna Rybacka',
    });
  }

  render() {
    const { center, zoom } = this.props;
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.API_MAPS }}
        defaultCenter={center}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
      />
    );
  }
}

MapComponent.propTypes = {
  zoom: PropTypes.number,
  center: PropTypes.node,
};

export default MapComponent;
