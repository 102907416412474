import React from 'react';
import MediaQuery from 'react-responsive';
import SEO from '../components/SEO/SEO';
import MainTemplate from '../templates/MainTemplate';
import Header from '../components/Header/Header';
import HeroTemplate from '../templates/HeroTemplate';
import NavButton from '../components/NavButton';
import AboutTemplate from '../templates/AboutTemplate';
import SpecializationTemplate from '../templates/SpecializationsTemplate';
import ContactTemplate from '../templates/ContactTemplate';
import FooterTemplate from '../templates/FooterTemplate';

const IndexPage = () => {
  return (
    <MainTemplate>
      <SEO title="Strona główna" />
      <Header hashPart />

      <MediaQuery minWidth={1024}>
        <HeroTemplate />
      </MediaQuery>

      <AboutTemplate hashName="o-nas" />

      <SpecializationTemplate hashName="specjalizacje" iconSize={54} />

      <ContactTemplate hashName="kontakt" />

      <FooterTemplate iconSize={42} />

      <MediaQuery maxWidth={1023}>
        <NavButton />
      </MediaQuery>
    </MainTemplate>
  );
};

export default IndexPage;
