import React from 'react';
import styled from 'styled-components';

const InfoWrapper = styled.div`
  width: 100%;
  height: 18%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation-name: mobileAnimation;
  animation-duration: 3s;

  ${({ theme }) => theme.mq.tablet} {
    height: 15%;
  }

  ${({ theme }) => theme.mq.desktopSmall} {
    height: 110px;
    border-bottom: 1px solid ${({ theme }) => theme.tertiaryGrey};
    animation: none;
  }

  @keyframes mobileAnimation {
    from {
      transform: translateY(-100%);
    }
    20%,
    80% {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
`;

const StyledMessage = styled.p`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: ${({ theme }) => theme.font.size.mm};
  margin: 0;

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.mmm};
  }
`;

const InfoBox = () => {
  return (
    <InfoWrapper>
      <StyledMessage>
        Pomyślnie wysłano wiadomość. <br /> Dziękujemy za kontakt.
      </StyledMessage>
    </InfoWrapper>
  );
};

export default InfoBox;
