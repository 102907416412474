import styled from 'styled-components';

export const StyledContent = styled.article`
  font-size: ${({ theme }) => theme.font.size.ms};
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  line-height: 1.5;
  p:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.mm};
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    font-size: ${({ theme }) => theme.font.size.mmm};
  }
`;

export const ContentWrapper = styled.div`
  width: ${({ theme }) => theme.widthValue.xxl};
  margin: 0 auto;

  ${({ theme }) => theme.mq.desktopSmall} {
    width: ${({ theme }) => theme.widthValue.xl};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  ${({ theme }) => theme.mq.desktopMedium} {
    width: ${({ theme }) => theme.widthValue.l};
  }

  ${({ theme }) => theme.mq.desktopBig} {
    grid-column-gap: 35px;
    grid-template-columns: 610px 1fr;
    width: ${({ theme }) => theme.widthValue.m};
  }

  ${({ theme }) => theme.mq.desktopHuge} {
    width: ${({ theme }) => theme.widthValue.s};
  }
`;

export const StyledName = styled.h1`
  position: relative;
  font-size: 3.6rem;
  margin: 1.5rem 0 3.5rem 0;
  letter-spacing: 0.6rem;
  text-align: center;
  font-weight: bold;

  ::before {
    position: absolute;
    width: 50%;
    content: '';
    bottom: -10%;
    left: 0%;
    border-bottom: 5px solid ${({ theme }) => theme.secondaryGreen};
  }
`;

export const imgStyle = {
  maxHeight: '1000px',
  maxWidth: '610px',
};

export const divStyle = {
  maxHeight: '1000px',
  maxWidth: '610px',
  width: '100%',
  margin: '0 auto 3rem auto',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  zIndex: '1',
};
