import React from 'react';
import scrollToElement from 'scroll-to-element';
import { StyledBox, StyledButton, StyledMotto } from './MottoBox.style';

const MottoBox = () => {
  const clickHandle = () => {
    scrollToElement('#kontakt', {
      offset: -120,
      duration: 300,
    });
    window.history.replaceState(null, null, '#kontakt');
  };

  return (
    <StyledBox>
      <StyledMotto>Bronimy Twoich praw!</StyledMotto>
      <StyledButton onClick={clickHandle}>Skontaktuj się z nami</StyledButton>
    </StyledBox>
  );
};

export default MottoBox;
