import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import MottoBox from '../components/Hero/MottoBox';
import {
  StyledBackgroundImage,
  StyledInnerWrapper,
} from './HeroTemplate.style';

const HeroTemplate = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg-patt.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = [
        `linear-gradient(to bottom,rgba(11,24,32, 1), rgba(11, 24, 32, 0.35))`,
        data.desktop.childImageSharp.fluid,
        `linear-gradient(to bottom,rgba(11,24,32, 1), rgba(11, 24, 32, 0.35))`,
      ];
      return (
        <StyledBackgroundImage
          Tag="section"
          fluid={imageData}
          backgroundColor="#040e18"
          alt="Background image"
        >
          <StyledInnerWrapper>
            <MottoBox />
          </StyledInnerWrapper>
        </StyledBackgroundImage>
      );
    }}
  />
);

export default HeroTemplate;
