import React, { Component } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './formStyle.css';
import {
  StyledForm,
  GroupWrapper,
  StyledInput,
  StyledLabel,
  StyledTextArea,
  SubmitWrapper,
  SubmitButton,
  StyledErrorSpan,
} from './ContactForm.style';
import InfoBox from './InfoBox';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    captchaResponse: '',
    sendingError: false,
    emailSent: false,
    verified: false,
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const { name, email, message, captchaResponse, verified } = this.state;

    if (verified && captchaResponse.length > 0) {
      axios
        .post(process.env.API_URL, {
          name,
          email,
          message,
        })
        .then(response => {
          response.status === 200
            ? this.setState({
                emailSent: true,
                name: '',
                email: '',
                message: '',
              })
            : null;
        })
        .then(() => {
          setTimeout(() => {
            this.setState({ sendingError: false, emailSent: false });
          }, 3000);
        })
        .catch(() => {
          this.setState({ sendingError: true });
        });
    } else {
      this.setState({ sendingError: true });
    }

    setTimeout(() => {
      this.setState({ sendingError: false });
    }, 3000);
  };

  handleInputChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  storeCaptcha = captchaResponse => {
    this.setState({ captchaResponse, verified: true });
  };

  render() {
    const { name, email, message, emailSent, sendingError } = this.state;

    return (
      <StyledForm onSubmit={this.handleFormSubmit}>
        <GroupWrapper>
          <span>
            {sendingError ? (
              <StyledErrorSpan>
                Wystąpił błąd - wiadomości nie wysłano
              </StyledErrorSpan>
            ) : null}
          </span>
        </GroupWrapper>
        <GroupWrapper>
          <StyledInput
            type="text"
            placeholder="Imię i nazwisko"
            id="name"
            name="name"
            value={name}
            onChange={this.handleInputChange}
            required
          />
          <StyledLabel htmlFor="name">Imię i nazwisko</StyledLabel>
        </GroupWrapper>
        <GroupWrapper>
          <StyledInput
            type="email"
            placeholder="Email"
            value={email}
            name="email"
            onChange={this.handleInputChange}
            required
          />
          <StyledLabel htmlFor="email">Adres email</StyledLabel>
        </GroupWrapper>
        <GroupWrapper>
          <StyledTextArea
            placeholder="Treść wiadomości"
            id="message"
            name="message"
            value={message}
            onChange={this.handleInputChange}
            cols="30"
            rows="10"
          />
        </GroupWrapper>

        <SubmitWrapper>
          <ReCAPTCHA
            style={{ display: 'inline-block' }}
            sitekey={process.env.CAPTCHA_KEY}
            onChange={this.storeCaptcha}
            hl="pl"
          />
          <SubmitButton type="submit">Wyślij</SubmitButton>
        </SubmitWrapper>

        {emailSent ? <InfoBox /> : null}
      </StyledForm>
    );
  }
}

export default ContactForm;
